<template>
  <v-dialog :width="width" persistent v-model="visible">
    <v-card :color="color">
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="() => $emit('ok')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        {{ message }}
        <vue-qr
          :text="code"
          :size="350"
          :correctLevel="3"
          :binarize="true"
          :binarizeThreshold="350"
          v-if="code"
        ></vue-qr>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import vuetify from "../../plugins/vuetify";
import VueQr from "vue-qr";
import axios from 'axios';
import _ from 'lodash';

export default {
  vuetify,
  components: {
    VueQr,
  },
  props: {
    width: {
      type: [String, Number],
      default: "auto",
    },
    idc: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "CoDi",
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
  },
  data() {
    return {
      visible: true,
      code: null,
      message: null,
    };
  },
  mounted() {
    this.getQrCode();
  },
  methods: {
    getQrCode() {
      axios
        .get(`api/v1/public/codi/charges/${this.idc}/qr`)
        .then((res) => {
          let code = _.get(res, "data.data", null);

          this.code = code;

          let message = _.get(res, "data.message", null);

          this.message = message;
        })
        .catch(() => {
          this.code = null;
        });
    },
  },
};
</script>