<template>
  <div class="box-container">

    <v-toolbar flat class="mb-5" color="transparent">
      <v-app-bar-nav-icon>
        <v-icon color="primary">mdi-qrcode</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title class="font-weight-bold">Operaciones</v-toolbar-title>

      <v-spacer></v-spacer>
      
      <v-btn
        outlined
        color="primary"
        class="mr-4"
        @click="() => verifyPayment($route.params.idc)"
        v-if="($route.params.idc||'').length>10"
      >
        Verificar
      </v-btn>

      <v-btn
        outlined
        color="primary"
        class="mr-4"
        @click="()=>showQr($route.params.idc)"
      >
        Ver QR
      </v-btn>
      
      <v-btn
        outlined
        color="primary"
        @click="() => {
          if(isAuthenticated){
            $router.push(`/entities/${$route.params.entidadId}/account/orders/${$route.params.ocId}/codi`).catch(()=>{})
          } else {
            $router.push(`/orders/${$route.params.ocId}/codi`).catch(()=>{})
          }
        }"
      >
        Regresar
      </v-btn>
    </v-toolbar>

    <v-row>
      <v-col cols="12">
        <v-card class="shadow-sm">
          <v-simple-table class="pa-5">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Concepto</th>
                  <th class="text-left">Folio</th>
                  <th class="text-left">Estado</th>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Solicitud</th>
                  <th class="text-left">Procesamiento</th>
                  <th class="text-left">Monto</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in payments" :key="item.idc">
                  <td>{{ item.cc || '-' }}</td>
                  <td>{{ item.id }}</td>
                  <td>
                    <v-chip :color="getStatusColor(item.e)">
                      {{ getStatusText(item.e) }}
                    </v-chip>
                  </td>
                  <td>{{ item.c.nb }}</td>
                  <td>{{ item.hs | toDate }}</td>
                  <td>{{ item.hp | toDate }}</td>
                  <td>{{ item.mt | currency }}</td>
                </tr>
                <tr v-if="payments && payments.length == 0">
                  <td colspan="5" class="text-center caption">No se han encontrado registros!</td>
                </tr>
              </tbody>
              
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import { mapState } from 'vuex';
import NQrCode from "@/components/newgen/NQrCode";

const TIPO_AVISO = {
  [-1]: "El cobro no ha sido aceptado!",
  0: "Aceptado por el comprador!",
  1: "El cobro ha sido acreditado!",
  2: "El cobro ha sido rechazado!",
  3: "Se ha realizado una devolucion por una transaccion no acreditada!",
  4: "El cobro ha sido pospuesto!",
  6: "Se ha realizado una devolucion por una transaccion acreditada!",
};

const MC_STATUS = {
  [-1]: {
    label: "No aceptado",
    color: "grey",
  },
  0: {
    label: "Aceptado",
    color: "yellow",
  },
  1: {
    label: "Acreditado",
    color: "green",
  },
  2: {
    label: "Rechazado",
    color: "red",
  },
  3: {
    label: "Devolucion no acreditada",
    color: "blue",
  },
  4: {
    label: "Pospuesto",
    color: "yellow",
  },
  6: {
    label: "Devolucion acreditada",
    color: "blue",
  },
};

export default {
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
  },
  data() {
    return {
      payments: [],
    };
  },
  mounted() {
    this.getPayments();
  },
  methods: {
    showQr(idc) {
      this.$dialog({ idc }, NQrCode)
        .then(() => {})
        .catch(() => {});
    },
    verifyPayment(id) {
      axios
        .post(`api/v1/codi/charges/${id}/verify`, {})
        .then((res) => {
          const tipoAvisoId = _.get(res, "data.data", -1);

          this.$dialog({
            title: "Verificacion de cobro",
            text: TIPO_AVISO[tipoAvisoId],
          }).then(() => {
            // DOES NOTHING
          });
        })
        .catch(() => {
          this.$dialog({
            title: "Verificacion de cobro",
            text: 'Hubo un error al solicitar el estado del cobro!',
          }).then(() => {
            // DOES NOTHING
          });
        });
    },
    getPayments() {
      const idc = _.get(this.$route, "params.idc");

      let url = `api/v1`;

      if (this.isAuthenticated) {
        url += `/codi/charges/${idc}/payments`;
      } else {
        url += `/public/codi/charges/${idc}/payments`;
      }

      axios
        .get(url)
        .then((res) => {
          let payments = _.get(res, "data.data", []);

          this.payments = payments;
        })
        .catch(() => {
          // DOES NOTHING
        });
    },
    getStatusColor(e) {
      return MC_STATUS[e].color;
    },
    getStatusText(e) {
      return MC_STATUS[e].label;
    },
  },
};
</script>

<style>
.payments-dialog {
  background-color: #ff00ff;
  min-width: 400px;
  width: auto;
}
</style>